@import 'fonts';
@import 'variables';
@import '~bulma-switch-control/bulma-switch-control';
@import '~bulma/bulma';
@import 'overrides';

html {
  font-family: 'Poppins';
}

.purple-bg {
  background-color: $miksit-purple;
}

.white-bg {
  background-color: $white;
}

.is-clickable {
  cursor: pointer;
}
