// Buttons
.button {
  color: $miksit-black;
  font-weight: 500;
}

// Modals
.modal-card-head {
  background-color: $white;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  padding: 15px 20px;
}

.modal-card-foot {
  border-top: 0;
  background-color: $white;
  padding: 5px;
}
