@import '~bulma/sass/utilities/initial-variables.sass';

// Colors
$miksit-purple: #5129cc;
$miksit-black: #111111;
$miksit-grey: #dce1e4;
$miksit-green: #29cc96;
$miksit-pink: #ff1664;
$miksit-warning: #fe8700;

// Config
$primary: $miksit-purple;
$success: $miksit-green;
$danger: $miksit-pink;
$warning: $miksit-warning;

$navbar-height: 5.375rem;
$navbar-breakpoint: $tablet;
$navbar-background-color: $primary;
$navbar-item-hover-background-color: $primary;
$navbar-item-color: $white;
$navbar-item-hover-color: $miksit-grey;
$navbar-item-active-color: $white;

$modal-content-width: 500px;

$switch-active-background-color: $miksit-green;
